import { Avatar, Box } from "@mui/material";
import Typography from "@mui/material/Typography";


export default function UserAppBarComponent({ open }) {

    return (
        <Box sx={open ? {
            display: 'flex',
            border: '1px solid #80808033',
            padding: '4px 8px 4px 8px',
            flexDirection: 'row',
            alignItems: 'center',
            columnGap: 2,
            background: '#FFEFE9',
            borderRadius: '6px',
            color: '#423a3a'
        }: {}}>
            <Avatar
                alt="Remy Sharp"
                src={'https://mui.com/static/images/avatar/1.jpg'}
                sx={{  cursor: 'pointer', boxShadow: 2, ':hover': open ? {} : { border: '1px solid gray', transitionDelay: '200ms' } }}
            />
            {open &&
                <Typography fontSize={'17px'} fontWeight={100} marginTop={'8px'}>
                    {'Jonathan Moura'}
                </Typography>
            }
        </Box>
    );

} 