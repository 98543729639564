import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import BarChartIcon from '@mui/icons-material/BarChart';
import DescriptionIcon from '@mui/icons-material/Description';
import HomeIcon from '@mui/icons-material/Home';
import PersonAddAlt1TwoToneIcon from '@mui/icons-material/PersonAddAlt1TwoTone';
import UsersPage from '../pages/Users/Users.page';
import HomePage from '../pages/Home/Home.page';

const Menus = [
    {
      segment: '/dashboard',
      title: 'Dashboard',
      icon: <HomeIcon />,
      component: <HomePage />
    },
    {
      segment: '',
      title: 'Pedidos',
      icon: <ShoppingCartIcon />,
    },
    {
      segment: '',
      title: 'Reports',
      icon: <BarChartIcon />,
      children: [
        {
          segment: '',
          title: 'Sales',
          icon: <DescriptionIcon />,
        },
        {
          segment: '',
          title: 'Traffic',
          icon: <DescriptionIcon />,
        },
      ],
    },
    {
      segment: '',
      title: 'Clientes',
      icon: <PersonAddAlt1TwoToneIcon />,
      component: <UsersPage />
    },
  ];

  export {Menus}