import { BarChart } from '@mui/x-charts/BarChart';
import { Box, Typography } from "@mui/material";
import { pieArcLabelClasses, PieChart } from '@mui/x-charts';
import { desktopOS, valueFormatter } from './sample.insight';






function HomePage() {
  const size = {
    width: 600,
    height: 300,
  };

  const data = {
    data: desktopOS,
    valueFormatter,
  };

  return (
    <Box
      sx={{
        py: 4,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        textAlign: "center",
      }}>
      <BarChart
        series={[
          { data: [35, 44, 24, 34, 15] },
          { data: [51, 6, 49, 30, 13] },
          { data: [15, 25, 30, 50, 18] },
          { data: [60, 50, 15, 25, 25] },
          { data: [35, 44, 24, 34, 35] },
          { data: [51, 6, 49, 30, 5] },
          { data: [15, 25, 30, 50, 5] },
          { data: [60, 50, 15, 25, 25] },
          { data: [35, 44, 24, 34, 12] },
          { data: [51, 6, 49, 30, 15] },
          { data: [15, 25, 30, 50, 17] },
          { data: [60, 50, 15, 25, 21] },
          { data: [35, 44, 24, 34, 2] },
          { data: [51, 6, 49, 30, 21] },
          { data: [15, 25, 30, 50, 13, 15] },
          { data: [60, 50, 15, 25, 2, 33] },
        ]}
        height={590}
        xAxis={[{ data: ['Q1', 'Q2', 'Q3', 'Q4', 'Q5', 'Q6'], scaleType: 'band' }]}
        margin={{ top: 10, bottom: 30, left: 40, right: 10 }}
      />

      <PieChart
        series={[
          {
            arcLabel: (item) => `${item.value}%`,
            arcLabelMinAngle: 35,
            arcLabelRadius: '60%',
            ...data,
          },
        ]}
        sx={{
          [`& .${pieArcLabelClasses.root}`]: {
            fontWeight: 'bold',
          },
        }}
        {...size}
      />
    </Box>
  );
}

export default HomePage;
