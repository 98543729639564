import { createTheme, styled, ThemeProvider, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import LogoutIcon from '@mui/icons-material/Logout';
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { Menus } from "../router/Menus";
import React, { useEffect, useState } from "react";
import { Avatar, Skeleton } from "@mui/material";
import UserAppBarComponent from "./components/user.appbar.component";

const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  variants: [
    {
      props: ({ open }) => open,
      style: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.enteringScreen,
        }),
      },
    },
  ],
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    variants: [
      {
        props: ({ open }) => open,
        style: {
          ...openedMixin(theme),
          '& .MuiDrawer-paper': openedMixin(theme),
        },
      },
      {
        props: ({ open }) => !open,
        style: {
          ...closedMixin(theme),
          '& .MuiDrawer-paper': closedMixin(theme),
        },
      },
    ],
  }),
);

export default function MainLayout() {
  const theme = createTheme({
    cssVariables: true,
    palette: {
      primary: {
        main: "#ff8266",
      },
      background: {
        default: "#f9ecec",
      },
    },
  });
  const [open, setOpen] = useState(false);
  const [expandedMenu, setExpandedMenu] = useState(null);
  let indexedLocation = localStorage.getItem('location');
  const [selectedMenu, setSelectedMenu] = useState(indexedLocation || 0);
  const [selectedSubmenu, setSelectedSubmenu] = useState(null);

  useEffect(() => {
    if (selectedMenu >= 0) {
      console.log(selectedMenu)
      localStorage.setItem('location', selectedMenu);
    }
  }, [selectedMenu]);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleToggleMenu = (m, index) => {
    if (!m.children) {
      setSelectedMenu(index);
      setSelectedSubmenu(null);
    } else {
      setOpen(true);
      setExpandedMenu(expandedMenu === index ? null : index);
    }
  };

  const handleSubmenuClick = (mIndex, submenuIndex, segment) => {
    setSelectedMenu(mIndex);
    setSelectedSubmenu(submenuIndex);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleLogout = () => {
    localStorage.removeItem('token');
    localStorage.setItem('isLogged', false);
    window.location.replace('/login')
  }

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ display: "flex" }}>
        <AppBar position="fixed" open={open}>
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              sx={[
                open && { display: 'none' },
              ]}>
              <MenuIcon />
            </IconButton>
            <IconButton
              sx={{ ...(!open && { display: "none" }) }}
              onClick={handleDrawerClose}
            >
              {theme.direction === "rtl" ? (
                <ChevronRightIcon />
              ) : (
                <ChevronLeftIcon sx={{ color: "white" }} />
              )}
            </IconButton>
            <Typography variant="h6" sx={{ border: '2px solid rgba(255,255,255,.1)', borderRadius: '4px', color: "white", fontFamily: 'sans-serif', fontWeight: '700', marginLeft: '15px', padding: '0 5px 0 5px', cursor: 'default' }} noWrap component="div">
              {Menus[selectedMenu].title}
            </Typography>
          </Toolbar>
        </AppBar>
        <Drawer variant="permanent" open={open} >
          <Toolbar>
            <IconButton
              onClick={handleDrawerClose}
              sx={{
                ...(!open && { display: "none" }),
                "&:hover": {
                  backgroundColor: "transparent",
                },
              }}
            >
              <img
                src={require("../assets/wala.png")}
                style={{ width: "100%", height: "85px" }}
                alt="Logo"
              />
            </IconButton>
          </Toolbar>
          <Divider />
          <List sx={{ height: '100%' }}>
            {Menus.map((m, index) => (
              <React.Fragment key={index}>
                <ListItem
                  disablePadding
                  sx={{
                    display: "block",
                    backgroundColor: selectedMenu == index
                      ? theme.palette.primary.main
                      : "inherit",
                  }}
                >
                  <ListItemButton
                    onClick={() => handleToggleMenu(m, index)}
                    sx={{
                      minHeight: 48,
                      justifyContent: open ? "initial" : "center",
                      px: 2.5,
                    }}
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: open ? 3 : "auto",
                        justifyContent: "center",
                      }}
                    >
                      {m.icon}
                    </ListItemIcon>
                    <ListItemText
                      primary={m.title}
                      sx={{ opacity: open ? 1 : 0 }}
                    />
                    <ListItemIcon
                      style={m.children ? {} : { display: "none" }}
                      sx={{
                        minWidth: 0,
                        mr: open ? 3 : "auto",
                        justifyContent: "center",
                      }}
                    >
                      {open ? (
                        expandedMenu === index ? (
                          <KeyboardArrowUpIcon />
                        ) : (
                          <KeyboardArrowDownIcon />
                        )
                      ) : null}
                    </ListItemIcon>
                  </ListItemButton>
                </ListItem>

                {m.children && expandedMenu === index && open && (
                  <List component="div" disablePadding>
                    {m.children.map((child, childIndex) => (
                      <ListItemButton
                        key={childIndex}
                        onClick={() =>
                          handleSubmenuClick(index, childIndex, child.segment)
                        }
                        sx={{
                          pl: 4,
                          minHeight: 40,
                          justifyContent: open ? "initial" : "center",
                          backgroundColor:
                            selectedMenu === index &&
                            selectedSubmenu === childIndex,
                        }}
                      >
                        <ListItemIcon sx={{ minWidth: 0, mr: open ? 3 : "auto" }}>
                          {child.icon}
                        </ListItemIcon>
                        <ListItemText
                          primary={child.title}
                          sx={{ opacity: open ? 1 : 0 }}
                        />
                      </ListItemButton>
                    ))}
                  </List>
                )}
              </React.Fragment>
            ))}
            <ListItem sx={{ display: 'flex', flexDirection: 'column', gap: 2, position: 'absolute', bottom: 0, margin: '0 0 10% 0' }}>
              {false ? <Skeleton animation='wave' variant="rounded" width={open ? 200 : 32} height={open ? 42 : 32} sx={{ border: '2px solid #ff8266' }} /> : <UserAppBarComponent open={open} />}
              {open ? null : <LogoutIcon onClickCapture={handleLogout} sx={{ transition: '80ms all',cursor: 'pointer', scale: '1.1', color:'#ff8266', marginRight: open ? '80%' : 'none', ":hover": { transitionDelay: '60ms', transform: 'scale(1.3)',background: '#ff8266', color: '#FFF', borderRadius: '6px'}}} />}
            </ListItem>
          </List>
        </Drawer>
        <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
          <DrawerHeader />
          {!selectedSubmenu && Menus[selectedMenu].component}
          {/* <Outlet /> Onde as páginas internas do layout serão renderizadas */}
        </Box>
      </Box>
    </ThemeProvider>
  );
}
