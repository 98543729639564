import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import config from "../../config";

function useAuthLogin(data, exec) {
    return useQuery({
        queryFn: async () => {
            const res = await axios.post(`${config.PRD_URI}/auth/login`, data);
            return res.data;
        },
        queryKey: ['auth-login'],
        enabled: exec,
        retry: false 
    })
}


export {
    useAuthLogin
}

/*
 controle - states
  -> local (useState)
  -> global (lib)
  -> http (react-query)
*/