import { Visibility, VisibilityOff } from "@mui/icons-material";
import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Container,
  createTheme,
  FormControl,
  IconButton,
  InputAdornment,
  Snackbar,
  TextField,
  ThemeProvider,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useAuthLogin } from "../../client/hooks/auth/auth.queries";

export default function LoginPage() {
  const [username, setUsername] = useState();
  const [password, setPassword] = useState();
  const [showPassword, setShowPassword] = useState(true);
  const [performLogin, setPerformLogin] = useState(false);
  const { data, isLoading, isError, error } = useAuthLogin({ email: username, password }, performLogin);
  const [alert, setAlert] = useState(false);

  const theme = createTheme({
    cssVariables: true,
    palette: {
      primary: {
        main: "#FF5733",
      },
    },
  });

  useEffect(() => {
    if (!isLoading && data?.success) {
      localStorage.setItem("isLogged", true);
      localStorage.setItem("token", data?.token);
      window.location.replace("/dashboard");
    }

    if (isError) {
      setAlert(true)
      setPerformLogin(false);
    }

  }, [isLoading])

  const execPerformLogin = () => {
    if (username && password) {
      setPerformLogin(true)
    }
  }

  return (
    <ThemeProvider theme={theme}>
      <Container maxWidth={false} sx={{
        background: "#ff7323",
        backgroundImage: 'url(https://i.ibb.co/7tfGHLK/1280x720.webp)',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundAttachment: 'fixed',
        position: 'fixed',
        top: 0, right: 0, bottom: 0, left: 0, zIndex: 0
      }}>
        <Snackbar
          autoHideDuration={5000}
          open={alert}
          onClose={() => setAlert(false)}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}>
          <Alert onClose={() => setAlert(false)}  severity="error">
            {isError && error.response.data.msg}
          </Alert>
        </Snackbar>
        <Box sx={{ display: "flex", paddingTop: "6%", justifyContent: "center" }}>
          <FormControl
            sx={{
              gap: 2,
              p: 4,
              boxShadow: 3,
              background: "#F9F1F0",
              borderRadius: "20px",
            }}
          >
            <Box sx={{ display: "flex", flexFlow: "column", p: 3 }}>
              <Typography variant="h4" textAlign={"center"}>
                {"Bem-Vindo"}
              </Typography>
              <Typography
                variant="caption"
                gutterBottom
                textAlign={"center"}
                color="gray"
              >
                {"Digite seu email e senha para realizar o login."}
              </Typography>
            </Box>
            <TextField label="Email" disabled={isLoading} onChange={(event) => setUsername(event.target.value)} type="email" size="small" />
            <TextField
              label="Senha"
              type={showPassword ? "password" : "text"}
              size="small"
              onChange={(event) => setPassword(event.target.value)}
              disabled={isLoading}
              slotProps={{
                input: {
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() => setShowPassword((show) => !show)}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                },
              }}
            />
            <Button
              onClick={() => execPerformLogin()}
              disabled={isLoading}
              sx={{ width: "100px", margin: "12px auto 0 auto" }}
            >
              {isLoading ? <CircularProgress size={24} /> : ` Login`}
            </Button>
          </FormControl>
        </Box>
      </Container>
    </ThemeProvider>
  );
}
