import React, { useEffect } from "react";
import "./index.css";
import { createTheme } from "@mui/material";
import LoginPage from "./pages/Login/Login.page";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import HomePage from "./pages/Home/Home.page";
import MainLayout from "./Layouts/Main.Layout";

export default function App() {
  // const navigate = useNavigate();

  useEffect(() => {
    if ((isLogged() == 'false' || !isLogged()) && (window.location.pathname !== "/login")) {
      window.location.replace("/login");
    }
  
    if (isLogged() == 'true' && window.location.pathname !== "/dashboard") {
      window.location.replace("/dashboard");
    }
  }, []);

  function isLogged() {
    return localStorage.getItem("isLogged");
  }

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/login" element={<LoginPage />} />
        <Route path="/dashboard" element={<MainLayout />} />
      </Routes>
    </BrowserRouter>
  );
}
